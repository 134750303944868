import React from "react";

const SectionContainer = (props) => {
  return (
    <section className="h-fit lg:h-90vh flex flex-col gap-16 px-4 lg:px-0 py-5 items-center justify-center">
      <div className="max-w-6xl mx-auto flex flex-col justify-center items-center gap-5 md:gap-10 px-4 lg:px-0 bounce-in-top">
        {props.children}
      </div>
    </section>
  );
};

export default SectionContainer;
