import React, { useEffect, useState } from "react";
import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo-main.png";

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activePage, setActivePage] = useState("/");
  const location = useLocation();

  // The current location.
  useEffect(() => {
    setActivePage(location.pathname);
  }, [location]);

  return (
    <header className="bg-white shadow-sm border-b sticky top-0 z-50">
      <nav
        className={`mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 ${
          mobileMenuOpen ? "hidden" : ""
        }`}
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Prajapati Company</span>
            <img className="h-8 w-auto" src={logo} alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Link
            to="/"
            className={`text-sm font-semibold leading-6 ${
              activePage === "/" || activePage === "/home"
                ? "text-gray-900 border-b-2 border-primary"
                : "text-gray-900 hover:text-primary"
            }`}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`text-sm font-semibold leading-6 ${
              activePage === "/about"
                ? "text-gray-900 border-b-2 border-primary"
                : "text-gray-900 hover:text-primary"
            } `}
          >
            About
          </Link>
          <Link
            to="/services"
            className={`text-sm font-semibold leading-6 ${
              activePage === "/services"
                ? "text-gray-900 border-b-2 border-primary"
                : "text-gray-900 hover:text-primary"
            } `}
          >
            Services
          </Link>
          <Link
            to="/gallery"
            className={`text-sm font-semibold leading-6 ${
              activePage === "/gallery"
                ? "text-gray-900 border-b-2 border-primary"
                : "text-gray-900 hover:text-primary"
            } `}
          >
            Gallery
          </Link>
          <Link
            to="/contact"
            className={`text-sm font-semibold leading-6 ${
              activePage === "/contact"
                ? "text-gray-900 border-b-2 border-primary"
                : "text-gray-900 hover:text-primary"
            } `}
          >
            Contact
          </Link>
        </Popover.Group>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden z-50"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Prajapati Company</span>
              <img className="h-8 w-auto" src={logo} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700 z-50"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  to="/"
                  className={`-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                    activePage === "/" || activePage === "/home"
                      ? "text-white bg-primary"
                      : "hover:text-primary"
                  }`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Home
                </Link>
                <Link
                  to="/about"
                  className={`-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                    activePage === "/about"
                      ? "text-white bg-primary"
                      : "hover:text-primary"
                  }`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  About
                </Link>
                <Link
                  to="/services"
                  className={`-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                    activePage === "/services"
                      ? "text-white bg-primary"
                      : "hover:text-primary"
                  }`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Services
                </Link>
                <Link
                  to="/gallery"
                  className={`-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                    activePage === "/gallery"
                      ? "text-white bg-primary"
                      : "hover:text-primary"
                  }`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Gallery
                </Link>
                <Link
                  to="/contact"
                  className={`-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                    activePage === "/contact"
                      ? "text-white bg-primary"
                      : "hover:text-primary"
                  }`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
