import wall from "../../assets/gallery/bhola/1.jpg";
import entrance1 from "../../assets/gallery/bhola/2.jpg";
import corridor1 from "../../assets/gallery/bhola/3.jpg";
import corridor2 from "../../assets/gallery/bhola/4.jpg";
import livingRoom1 from "../../assets/gallery/bhola/5.jpg";
import kitchen1 from "../../assets/gallery/bhola/6.jpg";
import kitchen2 from "../../assets/gallery/bhola/7.jpg";
import livingRoom2 from "../../assets/gallery/bhola/8.jpg";
import bedRoom from "../../assets/gallery/bhola/9.jpg";
import entrance2 from "../../assets/gallery/bhola/10.jpg";

const bholaSharmaGallery = [
  {
    id: 1,
    src: wall,
    thumb: wall,
    subHtml: "<h2>The Wall of the living room</h2>",
  },
  {
    id: 2,
    src: entrance1,
    thumb: entrance1,
    subHtml: "<h2>View from the living room of the entrance</h2>",
  },
  {
    id: 3,
    src: entrance2,
    thumb: entrance2,
    subHtml: "<h2>View from the living room of the entrance</h2>",
  },
  {
    id: 4,
    src: corridor1,
    thumb: corridor1,
    subHtml: "<h2>View of the Corridor from the living room</h2>",
  },
  {
    id: 5,
    src: corridor2,
    thumb: corridor2,
    subHtml: "<h2>View of the Corridor from the bed room</h2>",
  },
  {
    id: 6,
    src: kitchen1,
    thumb: kitchen1,
    subHtml: "<h2>View of the Modular Kitchen</h2>",
  },
  {
    id: 7,
    src: kitchen2,
    thumb: kitchen2,
    subHtml: "<h2>View of the Modular Kitchen</h2>",
  },
  {
    id: 8,
    src: livingRoom1,
    thumb: livingRoom1,
    subHtml: "<h2>View of the Living Room from the entrance</h2>",
  },
  {
    id: 9,
    src: livingRoom2,
    thumb: livingRoom2,
    subHtml: "<h2>View of the Living Room</h2>",
  },
  {
    id: 10,
    src: bedRoom,
    thumb: bedRoom,
    subHtml: "<h2>The Master Bed Room</h2>",
  },
];

export default bholaSharmaGallery;
