import React, { useState } from "react";

export const CardImgTop = ({ src, openLightBox, listIndex, title }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <div
      className="relative cursor-pointer"
      onMouseEnter={() => setShowOverlay(true)}
      onMouseLeave={() => setShowOverlay(false)}
      onTouchStart={() => setShowOverlay(true)}
      onTouchCancel={() => setShowOverlay(true)}
    >
      <div
        className={`flex absolute inset-1/2 justify-center items-center transition-all duration-700 ease-in-out ${
          showOverlay
            ? "opacity-100 w-full h-full top-0 left-0 bg-black/60"
            : "w-0 h-0 opacity-0 bg-transparent"
        }`}
      >
        <button
          onClick={() => openLightBox(listIndex)}
          className={`border border-white text-white w-20 h-9 hover:bg-white hover:text-primary transition-all ease-in-out ${
            showOverlay ? "opacity-100" : "opacity-0"
          }`}
        >
          View
        </button>
      </div>
      <img
        src={src}
        className="object-cover w-full"
        loading="lazy"
        style={{ height: "288px" }}
        alt={title}
      />
    </div>
  );
};
