import React, { Suspense } from "react";
import ContactCallAction from "./ContactCallAction";
import Section from "../Section";
import LoadingPage from "../LoadingPage";

const ContactCallActionContainer = () => {
  return (
    <Section
      classes={
        "h-fit relative bg-blend-multiply bg-gradient-to-r from-amber-500 to-orange-400 p-8"
      }
    >
      <div className="max-w-6xl mx-auto flex flex-col justify-center items-center gap-1 px-4 lg:px-0">
        <Suspense fallback={<LoadingPage />}>
          <ContactCallAction />
        </Suspense>
      </div>
    </Section>
  );
};

export default ContactCallActionContainer;
