import React, { Suspense, useEffect, useRef, useState } from "react";
import mainLogo from "../assets/logo-main.jpg";
import SectionContainer from "../components/Common/SectionContainer";
import Section from "../components/Common/Section";
import AboutSection from "../components/HomePage/AboutSection";
import LoadingPage from "../components/Common/LoadingPage";
import LatestWorkWrapper from "../components/HomePage/LatestWorkWrapper";
import ServicesWrapper from "../components/HomePage/ServicesWrapper";

export const Home = () => {
  const latestWork = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Section classes={"h-1/2 block md:hidden my-3 mb-2"}>
        <img className="h-full w-full" src={mainLogo} />
      </Section>
      <SectionContainer>
        <Suspense fallback={<LoadingPage />}>
          <AboutSection />
          <button
            onClick={() => {
              latestWork.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "end",
              });
            }}
            className="my-3 hidden md:block animate-bounce bg-white dark:bg-slate-800 p-2 w-10 h-10 ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center"
          >
            <svg
              className="w-6 h-6 text-gray-500"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
            </svg>
          </button>
        </Suspense>
      </SectionContainer>
      <Section
        classes={`h-fit lg:h-90vh relative bg-blend-multiply bg-gradient-to-r from-amber-500 to-orange-400 p-8`}
      >
        <LatestWorkWrapper />
        <div className="absolute bottom-0 right-0" ref={latestWork}></div>
      </Section>
      <Section classes={"h-auto relative p-8"}>
        <ServicesWrapper />
      </Section>
    </>
  );
};
