import React, { Suspense, useEffect } from "react";
import LoadingPage from "../components/Common/LoadingPage";
import ServicesSection from "../components/HomePage/ServicesSection";
import Breadcrumb from "../components/Common/BreadcrumbBanner/Breadcrumb";

export const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Breadcrumb title={"SERVICEs"} mainNav={"Services"} />
      <div className="max-w-6xl mx-auto flex flex-col justify-center items-center gap-4 px-4 lg:px-0 my-6 mt-7">
        <Suspense fallback={<LoadingPage />}>
          <ServicesSection />
        </Suspense>
      </div>
    </div>
  );
};
