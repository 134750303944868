import React, { useEffect } from "react";
import Breadcrumb from "../components/Common/BreadcrumbBanner/Breadcrumb";
import ContactForm from "../components/ContactForm/ContactForm";

export const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title={"Contact"} mainNav={"Contact"} />
      <div className="max-w-6xl mx-auto flex flex-col justify-center items-center gap-4 px-4 lg:px-0 my-6 mt-7">
        <div className="flex flex-wrap md:flex-nowrap justify-between gap-20 my-3 px-3">
          <ContactForm />
          <div className="items-start text-left w-full md:w-5/6 slide-in-right mt-4 md:mt-0">
            <h1 className="text-2xl font-bold uppercase my-3 block">Find Us</h1>
            <p className="text-base font-light mt-0 mb-4">
              If you are looking for some architectural related work to be done,
              don't hesitate to pay us a visit.
            </p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3564.410908823192!2d88.37364077516818!3d26.69931677677508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e4469b8adadb89%3A0xcf84daf8ee9bb8e9!2sPrajapati%20Construction%20Company!5e0!3m2!1sen!2sin!4v1683637776462!5m2!1sen!2sin"
              width="600"
              height="500"
              style={{ border: 0, width: "100%" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};
