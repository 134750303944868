import React from "react";
import { CardImgTop } from "./CardImgTop";

export const Card = ({ src, title, openLightBox, listIndex }) => {
  return (
    <div className="border bg-white overflow-hidden">
      <CardImgTop
        src={src}
        openLightBox={openLightBox}
        listIndex={listIndex}
        title={title}
      />
      <p
        className="py-5 text-primary font-bold hover:text-hoverColor cursor-pointer"
        onClick={() => openLightBox(listIndex)}
      >
        {title}
      </p>
    </div>
  );
};
