import React, { useCallback, useEffect, useRef, useState } from "react";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import veerJawan from "../../assets/works/veer_jawan.png";
import drukResidence from "../../assets/works/druk_residence.png";
import bholaSharma from "../../assets/works/bhola_sharma.png";
import { Card } from "../Cards/Card";
import bholaSharmaGallery from "../Common/BholaSharma";
import drukGallery from "../Common/Druk";
import veerJawanGallery from "../Common/VeerJawan";

const LatestWork = () => {
  const [items, setItems] = useState([]);
  const lightGallery = useRef(null);
  const openLightBox = (index) => {
    if (index === 3) {
      setItems(bholaSharmaGallery);
      if (items.length > 0 && items[0].thumb === bholaSharmaGallery[0].thumb)
        lightGallery.current.openGallery();
    } else if (index === 2) {
      setItems(drukGallery);
      if (items.length > 0 && items[0].thumb === drukGallery[0].thumb)
        lightGallery.current.openGallery();
    } else {
      setItems(veerJawanGallery);
      if (items.length > 0 && items[0].thumb === veerJawanGallery[0].thumb)
        lightGallery.current.openGallery();
    }
  };

  useEffect(() => {
    if (items.length > 0) {
      lightGallery.current.refresh(items);
      lightGallery.current.openGallery();
    }
  }, [items]);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  return (
    <>
      <h1 className="text-white text-2xl font-bold uppercase my-3">
        Latest Works
      </h1>
      <LightGallery
        elementClassNames="custom-classname"
        dynamic
        dynamicEl={items}
        download={false}
        getCaptionFromTitleOrAlt={true}
        onInit={onInit}
      ></LightGallery>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-3">
        <Card
          src={veerJawan}
          title={"Veer Jawan - Gurung Nagar, Siliguri"}
          openLightBox={openLightBox}
          listIndex={1}
        />
        <Card
          src={drukResidence}
          title={"Druk Residence - Devidanga, Siliguri"}
          openLightBox={openLightBox}
          listIndex={2}
        />
        <Card
          src={bholaSharma}
          title={"Bhola Sharma, Siliguri - Interior"}
          openLightBox={openLightBox}
          listIndex={3}
        />
      </div>
    </>
  );
};

export default LatestWork;
