import React from "react";

const Tooltip = ({ content }) => {
  return (
    <div className="absolute bottom-0 flex flex-col items-center invisible mb-6 group-hover:visible transition-opacity duration-500 ease-in-out opacity-0 group-hover:opacity-100">
      <span
        style={{ fontSize: "9px" }}
        className="relative z-10 p-1 leading-none text-white whitespace-no-wrap bg-black shadow-lg"
      >
        {content}
      </span>
      <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
    </div>
  );
};

export default Tooltip;
