import React from "react";
import Section from "../Section";
import { HomeIcon, PhotoIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

const Breadcrumb = ({ title, mainNav, subNav }) => {
  return (
    <Section
      classes={
        "h-2/4 relative bg-blend-multiply bg-gradient-to-r from-amber-500 to-orange-400 p-8 gap-2"
      }
    >
      <h1
        className={`text-white text-3xl font-bold uppercase ${
          title === "Contact" ? "mt-3 mb-0" : "my-3"
        }`}
      >
        {title}
      </h1>
      {title === "Contact" && (
        <p className="text-white text-base font-light mt-0 mb-3">
          It would be a pleasure to serve you.
        </p>
      )}
      <ul className="flex flex-row justify-center items-center gap-2 text-white text-sm font-normal">
        <li className="flex items-center">
          <Link to={"/"} className="flex text-white items-center">
            <HomeIcon className="h-4 w-4 text-white text-sm" /> &nbsp;Home
          </Link>
        </li>
        <li className="text-white">/</li>
        <li className="text-white">
          {subNav ? (
            <>
              <Link
                to={`/${mainNav.toString().toLowerCase()}`}
                className="flex text-white items-center"
              >
                <PhotoIcon className="h-4 w-4 text-white text-sm" /> &nbsp;
                {mainNav}
              </Link>
            </>
          ) : (
            <span className="text-white">{mainNav}</span>
          )}
        </li>
        {subNav && (
          <>
            <li className="text-white">/</li>
            <li className="text-white">{subNav}</li>
          </>
        )}
      </ul>
    </Section>
  );
};

export default Breadcrumb;
