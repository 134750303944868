import React, { Suspense, useEffect } from "react";
import Breadcrumb from "../components/Common/BreadcrumbBanner/Breadcrumb";
import PhotoContent from "../components/Common/PhotoContent";
import whoWeAre from "../assets/about/who-we-are.png";
import aim from "../assets/about/aim.png";
import mission from "../assets/about/mission.png";
import vision from "../assets/about/vision.png";
import env from "../assets/about/environment.png";
import approach from "../assets/about/approach.png";
import LoadingPage from "../components/Common/LoadingPage";

export const About = () => {
  const data = [
    {
      id: 1,
      pos: "left",
      title: "Who Are We?",
      content: `We believe tomorrow is better than today. We make the best of everyday to create a lucrative future, buildings small benchmarks to create milestones. We mainly work on Integrated Architecture & Structural Design.`,
      image: whoWeAre,
    },
    {
      id: 2,
      pos: "right",
      title: "Our Aim",
      content: `Satisfaction of clients & delivering a design which is sound, economical and matches the requirement of our clients.`,
      image: aim,
    },
    {
      id: 3,
      pos: "left",
      title: "Our Mission",
      content: `To raise the benchmark of quality, service and design with every project, while valuing customer satisfaction and stakeholder trust.`,
      image: mission,
    },
    {
      id: 4,
      pos: "right",
      title: "Our Vision",
      content: `To ascend to global heights of excellence in the sector of planning, structural designing and construction by continually offering world class design, innovation, services as well as building a loyal and reputed clientele base.`,
      image: vision,
    },
    {
      id: 5,
      pos: "left",
      title: "Environment",
      content: `We evolve, responsibly. Nature is one of our most precious allies and we strive to protect and preserve it through eco-friendly design and execution advancements.`,
      image: env,
    },
    {
      id: 6,
      pos: "right",
      title: "Approach",
      content: `PCC sees teamwork on every project as their success ingredient and are resoponsible for creating this environment. Teams are built by combining the strengths of the individual members and refining skills to meet and exceed the Client's expectations. We believe in a hands-on approach on all our projects.`,
      image: approach,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Breadcrumb
        title={"ABOUT PRAJAPATI CONSTRUCTION COMPANY"}
        mainNav={"About"}
      />
      <div className="max-w-6xl mx-auto flex flex-col justify-center items-center gap-2 px-4 lg:px-0">
        {data.map((item) => (
          <Suspense fallback={<LoadingPage />}>
            <div className="py-4 my-2">
              <PhotoContent
                key={item.id}
                imgPos={item.pos}
                image={item.image}
                title={item.title}
                content={item.content}
              />
            </div>
          </Suspense>
        ))}
      </div>
    </div>
  );
};
