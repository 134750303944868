import React, { useEffect, useState } from "react";
import architecture from "../../assets/services/architecture-design.png";
import interior from "../../assets/services/interior-white.png";
import project from "../../assets/services/project-mgt.png";
import structure from "../../assets/services/earthquake-and-home.png";
import estimate from "../../assets/services/estimate.png";
import construction from "../../assets/services/construction.png";
import workshop from "../../assets/services/training.png";
import supervision from "../../assets/services/supervised.png";
import Service from "../Services/Service";
import { useLocation } from "react-router-dom";

const ServicesSection = () => {
  const location = useLocation();
  const [showTitle, setShowTitle] = useState(true);
  const [showSubTitle, setShowSubTitle] = useState(false);
  const data = [
    {
      id: 1,
      image: architecture,
      title: "Architecture Design",
      content: `It is the art of designing spaces and is the product of
                planning, designing and constructing buildings and other
                structure.`,
    },
    {
      id: 2,
      image: interior,
      title: "Interior Designing",
      content: `It is the art of enhancing the interior and sometime extrior of
                a space of building, to achieve a healthier and more
                aesthetically pleasing environment.`,
    },
    {
      id: 3,
      image: project,
      title: "Project Management",
      content: `It includes management of projects from inception to completion,
                i.e., monitoring of activities like planning, designing,
                scheduling, etc. to ensure better and efficient contruction.`,
    },
    {
      id: 4,
      image: structure,
      title: "Structural Designing & Detailing",
      content: `It involves pre-design consultation through design to construction phase services to create a strong, earthquake resistant, durable and efficient structure.`,
    },
    {
      id: 5,
      image: estimate,
      title: "Estimation",
      content: `Estimating and costing in construction management is the calculation of quantities of materials, tools, equipments, labors etc. and cost associated with them.`,
    },
    {
      id: 6,
      image: construction,
      title: "Construction",
      content: `Construction is the building of things such as houses, factories, roads, and bridges.`,
    },
    {
      id: 7,
      image: workshop,
      title: "Civil Engineering Workshop",
      content: `PCC provides workshop in colleges to make better engineers. Workshop helps students to learn latest technologies, software and also helps them to learn practical things.`,
    },
    {
      id: 8,
      image: supervision,
      title: "Technical Supervision",
      content: `PCC provides technical supervision through the project development phase.`,
    },
  ];

  useEffect(() => {
    if (location.pathname === "/") {
      setShowTitle(true);
      setShowSubTitle(false);
    } else if (location.pathname === "/services") {
      setShowTitle(false);
      setShowSubTitle(true);
    }
  }, [location.pathname]);
  return (
    <>
      <h1
        className={`text-2xl text-primary font-bold uppercase my-3 ${
          showTitle ? "block" : "hidden"
        }`}
      >
        Services Offered
      </h1>
      <p
        className={`max-w-4xl text-base leading-loose py-5 font-bold text-black  ${
          showSubTitle ? "block" : "hidden"
        }`}
      >
        We believe tomorrow is better than today. We make the best of everyday
        to create a lucrative future, buildings small benchmarks to create
        milestones. We mainly work on Integrated Architecture & Structural
        Design.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-center rounded-lg">
        {data.map((item) => (
          <div className="flex items-center" key={`serviceWrapper_${item.id}`}>
            <Service
              key={item.id}
              image={item.image}
              title={item.title}
              content={item.content}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ServicesSection;
