import ccct1 from "../../assets/gallery/workshop/1.png";
import ccct2 from "../../assets/gallery/workshop/2.png";
import ccct3 from "../../assets/gallery/workshop/3.png";
import ccct4 from "../../assets/gallery/workshop/4.png";
import ccct5 from "../../assets/gallery/workshop/5.png";
import ccct6 from "../../assets/gallery/workshop/6.png";

const civilWorkshopGallery = [
  {
    id: 1,
    src: ccct1,
    thumb: ccct1,
    subHtml: "<h2>Workshop at CCCT</h2>",
  },
  {
    id: 2,
    src: ccct2,
    thumb: ccct2,
    subHtml: "<h2>Workshop at CCCT</h2>",
  },
  {
    id: 3,
    src: ccct3,
    thumb: ccct3,
    subHtml: "<h2>Workshop at CCCT</h2>",
  },
  {
    id: 4,
    src: ccct4,
    thumb: ccct4,
    subHtml: "<h2>Workshop at CCCT</h2>",
  },
  {
    id: 5,
    src: ccct5,
    thumb: ccct5,
    subHtml: "<h2>Workshop at CCCT</h2>",
  },
  {
    id: 6,
    src: ccct6,
    thumb: ccct6,
    subHtml: "<h2>Workshop at CCCT</h2>",
  },
];

export default civilWorkshopGallery;
