import residence from "../../assets/gallery/druk/1.JPG";
import window from "../../assets/gallery/druk/2.jpg";
import door from "../../assets/gallery/druk/3.jpg";
import balcony from "../../assets/gallery/druk/4.jpg";
import choesham1 from "../../assets/gallery/druk/5.jpg";
import choesham2 from "../../assets/gallery/druk/6.jpg";
import bedroom from "../../assets/gallery/druk/7.jpg";
import livingRoom from "../../assets/gallery/druk/8.jpg";
import shower from "../../assets/gallery/druk/9.jpg";

const drukGallery = [
  {
    id: 1,
    src: residence,
    thumb: residence,
    subHtml: "<h2>The Residence</h2>",
  },
  {
    id: 2,
    src: window,
    thumb: window,
    subHtml: "<h2>View of the external window</h2>",
  },
  {
    id: 3,
    src: door,
    thumb: door,
    subHtml: "<h2>View of the entrance door</h2>",
  },
  {
    id: 4,
    src: balcony,
    thumb: balcony,
    subHtml: "<h2>View of the Corridor from the bed room</h2>",
  },
  {
    id: 5,
    src: choesham1,
    thumb: choesham1,
    subHtml: "<h2>View of the Choesham</h2>",
  },
  {
    id: 6,
    src: choesham2,
    thumb: choesham2,
    subHtml: "<h2>View of the Choesham</h2>",
  },
  {
    id: 7,
    src: bedroom,
    thumb: bedroom,
    subHtml: "<h2>View of the Bedroom</h2>",
  },
  {
    id: 8,
    src: livingRoom,
    thumb: livingRoom,
    subHtml: "<h2>View of the Living Room</h2>",
  },
  {
    id: 9,
    src: shower,
    thumb: shower,
    subHtml: "<h2>View of the Shower</h2>",
  },
];

export default drukGallery;
