import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import mail_truck from "../../assets/mail-truck.gif";

const ContactForm = () => {
  const [showForm, setShowForm] = useState(true);
  const [responseError, setResponseError] = useState({
    msg: "",
    isVisible: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const reCapRef = useRef();

  const onSubmit = async (data) => {
    const token = await reCapRef.current.executeAsync();
    reCapRef.current.reset();
    const mailData = {
      name: data.name,
      email: data.email,
      subject: data.subject,
      message: data.message,
      token: token,
    };
    const response = await axios.post("/send-mail", mailData);
    if (response.status === 200 && response.data.message === "Mail send") {
      setShowForm(false);
      setResponseError({
        msg: "",
        isVisible: false,
      });
    } else {
      setShowForm(true);
      if (response.data.message === "token authentication failed")
        setResponseError({
          msg: "Seems like you are a bot. Validate yourself",
          isVisible: true,
        });
      else {
        setResponseError({
          msg: "Sorry, could not process your request. Try again later",
          isVisible: true,
        });
      }
    }
    reset();
  };
  return (
    <div className="text-left w-full md:w-3/4 slide-in-left">
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        size="invisible"
        ref={reCapRef}
      />
      <h1 className="text-2xl font-bold uppercase my-3 block">CONTACT US</h1>
      <p className="text-base font-light mt-0 mb-4">
        If you can't make it to our office fill in the contact form or call us
        or mail us, we'd love to hear from you.
      </p>
      {showForm ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          {responseError.isVisible && (
            <p className="p-2 py-1 bg-rose-500/50 text-rose-900 rounded">
              {responseError.msg}
            </p>
          )}
          <input
            {...register("name", { required: true })}
            className={`border ${
              errors.name ? "border-rose-600 mb-1" : "my-3"
            } shadow appearance-none rounded w-full py-2 px-3 placeholder-black/60 leading-tight focus:outline-1 focus:outline-primary focus:shadow-outline`}
            type="text"
            placeholder="Your Name"
          />
          {errors.name && (
            <p className="text-rose-600 text-sm text-bold mt-0 mb-3 pt-0">
              Name is required.
            </p>
          )}
          <input
            {...register("email", {
              required: { value: true, message: "Email is required" },
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email address",
              },
            })}
            className={`border ${
              errors.email ? "border-rose-600 mb-1" : "my-3"
            } shadow appearance-none rounded w-full py-2 px-3 placeholder-black/60 leading-tight focus:outline-1 focus:outline-primary focus:shadow-outline`}
            type="email"
            placeholder="Your Email"
          />
          {errors.email && (
            <p className="text-rose-600 text-sm text-bold mt-0 mb-3 pt-0">
              {errors.email?.message}
            </p>
          )}
          <input
            {...register("subject", { required: true })}
            className={`border ${
              errors.subject ? "border-rose-600 mb-1" : "my-3"
            } shadow appearance-none rounded w-full py-2 px-3 placeholder-black/60 leading-tight focus:outline-1 focus:outline-primary focus:shadow-outline`}
            type="subject"
            placeholder="Subject"
          />
          {errors.subject && (
            <p className="text-rose-600 text-sm text-bold mt-0 mb-3 pt-0">
              Please enter a subject.
            </p>
          )}
          <textarea
            {...register("message", { required: true })}
            className={`border ${
              errors.message ? "border-rose-600 mb-1" : "my-3"
            } shadow appearance-none rounded w-full py-2 px-3 placeholder-black/60 leading-tight focus:outline-1 focus:outline-primary focus:shadow-outline h-56 resize-none`}
            placeholder="Message"
          ></textarea>
          {errors.message && (
            <p className="text-rose-600 text-sm text-bold mt-0 mb-3 pt-0">
              Please enter a message.
            </p>
          )}
          <button
            type="submit"
            className="p-3 bg-primary text-white shadow-none hover:shadow-lg"
          >
            Send Message
          </button>
        </form>
      ) : (
        <p className="flex justify-center items-center flex-col h-3/6">
          <img
            src={mail_truck}
            loading="lazy"
            className="h-32 w-32"
            alt="email delivering"
          />
          <span className="text-2xl text-bold text-green-800 py-5">
            Thank you. The Mailman is on his way
          </span>
          <span className="text-sm text-bold text-gray-500">
            We'll get back to you as soon as possible
          </span>
        </p>
      )}
    </div>
  );
};

export default ContactForm;
