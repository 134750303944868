import sieveAnalysis from "../../assets/gallery/supervision/1.png";
import waterProofing from "../../assets/gallery/supervision/2.png";
import aivt from "../../assets/gallery/supervision/3.png";
import flakiness from "../../assets/gallery/supervision/4.png";
import semiPile from "../../assets/gallery/supervision/5.png";
import slabCastingDoors from "../../assets/gallery/supervision/6.png";
import surveying from "../../assets/gallery/supervision/7.png";
import slabCasting from "../../assets/gallery/supervision/8.png";

const techSupervisionGallery = [
  {
    id: 1,
    src: sieveAnalysis,
    thumb: sieveAnalysis,
    subHtml: "<h2>Sieve Analysis</h2>",
  },
  {
    id: 2,
    src: waterProofing,
    thumb: waterProofing,
    subHtml: "<h2>Water Proofing Masterpel 777</h2>",
  },
  {
    id: 3,
    src: aivt,
    thumb: aivt,
    subHtml: "<h2>Aggregate Impact Value Test</h2>",
  },
  {
    id: 4,
    src: flakiness,
    thumb: flakiness,
    subHtml: "<h2>Flakiness and Elongation Indices</h2>",
  },
  {
    id: 5,
    src: semiPile,
    thumb: semiPile,
    subHtml: "<h2>Semi pile foundation to increase soil bearing capacity</h2>",
  },
  {
    id: 6,
    src: slabCastingDoors,
    thumb: slabCastingDoors,
    subHtml: "<h2>Slab Casting Doors</h2>",
  },
  {
    id: 7,
    src: slabCasting,
    thumb: slabCasting,
    subHtml: "<h2>Slab Casting - Siliguri</h2>",
  },
  {
    id: 8,
    src: surveying,
    thumb: surveying,
    subHtml: "<h2>Surveying</h2>",
  },
];

export default techSupervisionGallery;
