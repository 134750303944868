import React from "react";
import { useInView } from "react-intersection-observer";

const PhotoContent = ({ imgPos, image, content, title }) => {
  const [ref, inView] = useInView({ threshold: 0.5, triggerOnce: true });
  return (
    <>
      <h2 className="text-xl text-primary font-bold mt-6 uppercase">{title}</h2>
      <div
        ref={ref}
        className="flex flex-wrap md:flex-nowrap justify-between items-center gap-4 my-3 px-3"
      >
        <img
          src={image}
          className={`${
            imgPos === "left"
              ? `block h-60 lg:h-64 order-none w-full ${
                  inView ? "slide-in-left" : "opacity-0"
                }`
              : "hidden"
          }`}
          loading="lazy"
          alt={title}
        />
        <p
          className={`text-base font-light max-w-sm text-justify leading-loose ${
            imgPos === "left"
              ? inView
                ? "slide-in-right"
                : "opacity-0"
              : inView
              ? "slide-in-left"
              : "opacity-0"
          }`}
        >
          {content}
        </p>
        <img
          src={image}
          className={`${
            imgPos === "right"
              ? `block h-64 order-first md:order-1 w-full  ${
                  inView ? "slide-in-right" : "opacity-0"
                }`
              : "hidden"
          }`}
          loading="lazy"
          alt={title}
        />
      </div>
    </>
  );
};

export default PhotoContent;
