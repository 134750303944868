import React from "react";

const Service = ({ image, title, content }) => {
  return (
    <div className="p-2">
      <div className="flex gap-3 gap-y-2 items-center">
        <div className="rounded-full bg-primary h-24 w-24 flex justify-center items-center  self-center shrink-0">
          <img src={image} alt="Interior Design" className="w-12 h-12" />
          {/* <BuildingSvg classes={"w-10 h-10 text-white drop-shadow-xl"} /> */}
        </div>
        <div className="leading-3 text-left self-start">
          <h2 className="text-sm font-bold text-black">{title}</h2>
          <p className="text-xs leading-6 text-gray-500">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default Service;
