import React from "react";
import mainLogo from "../../assets/logo-main.jpg";

const AboutSection = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-10">
        <div className="col-span-7">
          <h1 className="text-2xl text-primary font-bold uppercase my-3">
            About Us
          </h1>
          <p className="text-justify leading-loose">
            Established in 2016, specialized in architecture, structural
            designing &amp; project management. We fulfill every relationship,
            through quality deliveries. Each project is a testament to the
            satisfied needs and expectations of our customers. We believe
            tomorrow is better than today. We make the best of everyday to
            create a lucrative future, buildings small benchmarks to create
            milestones. We mainly work on Integrated Architecture & Structural
            Design. <br /> <br />
            We are based on Siliguri, Gangtok, Kalimpong and Kurseong with a
            highly qualified and professional team who are established
            Architects and Engineers.
          </p>
        </div>
        <div className="col-span-5 hidden md:block">
          <img className="h-full w-full" src={mainLogo} />
        </div>
      </div>
    </>
  );
};

export default AboutSection;
