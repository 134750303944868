import React from "react";
import loader from "../../assets/loader.gif";

const LoadingPage = () => {
  return (
    <div className="h-fit lg:h-90vh w-full flex flex-col justify-center items-center">
      <img src={loader} alt="Loading Prajapati Company" className="h-1/4" />
      <p>Loading.... Please wait</p>
    </div>
  );
};

export default LoadingPage;
