import React from "react";
import fb from "../assets/footer/facebook-app-symbol.png";
import mail from "../assets/footer/mail.png";
import tel from "../assets/footer/telephone-call.png";
import Tooltip from "../components/Common/Tooltip";

export const Footer = () => {
  return (
    <div className="mt-3 pt-3">
      <hr />
      <div className="p-6 px-8 flex flex-col md:flex-row justify-between items-center gap-4">
        <div className="flex md:hidden justify-end gap-3">
          <a
            href="https://www.facebook.com/prajapaticonstruction/"
            target="_blank"
          >
            <img src={fb} className="h-6" />
          </a>
          <a
            href="mailto:prajapaticompany666@gmail.com"
            className="relative flex flex-col items-center group"
          >
            <img src={mail} className="h-6" />
            <Tooltip content={"prajapaticompany666@gmail.com"} />
          </a>
          <a
            href="tel:+918967049260"
            className="relative flex flex-col items-center group"
          >
            <img src={tel} className="h-6" />
            <Tooltip content={"+918967049260 / +919734933817"} />
          </a>
        </div>
        <div>
          <p className="text-xs">
            Copyright: &#169; 2017 - 2023 Prajapati Construction Company. All
            Rights Reserved.
          </p>
          <p className="text-xs mt-3">
            Design and Developed by Mrinaldeep Chakravorty
          </p>
        </div>
        <div className="hidden md:flex flex-col md:flex-row justify-end gap-3">
          <a
            href="https://www.facebook.com/prajapaticonstruction/"
            target="_blank"
          >
            <img src={fb} className="h-6" />
          </a>
          <a
            href="mailto:prajapaticompany666@gmail.com"
            className="relative flex flex-col items-center group"
          >
            <img src={mail} className="h-6" />
            <Tooltip content={"prajapaticompany666@gmail.com"} />
          </a>
          <a
            href="tel:+918967049260"
            className="relative flex flex-col items-center group"
          >
            <img src={tel} className="h-6" />
            <Tooltip content={"+918967049260 / +919734933817"} />
          </a>
        </div>
      </div>
    </div>
  );
};
