import veerJawan from "../../assets/works/veer_jawan.png";

const veerJawanGallery = [
  {
    id: 1,
    src: veerJawan,
    thumb: veerJawan,
    subHtml: "<h2>Veer Jawan</h2>",
  },
];

export default veerJawanGallery;
