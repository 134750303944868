import React from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const ContactCallAction = () => {
  const [ref, inView] = useInView({ threshold: 0.5 });
  return (
    <>
      <h1 className="text-white text-2xl font-bold uppercase my-3">
        SO WHAT DO YOU THINK ?
      </h1>
      <p className="my-3 text-white">
        If you are looking for some architectural related work to be done, don't
        hesitate to pay us a visit. It would be a pleasure to serve you. Or if
        you can't make it to our office fill in the contact form or call us or
        mail us, we'd love to hear from you.
      </p>

      <Link to={"contact"} ref={ref}>
        <button
          className={`bg-white px-4 py-3 text-primary mt-4 hover:shadow-lg ${
            inView ? "pulsate-fwd" : ""
          } `}
        >
          Contact Us
        </button>
      </Link>
    </>
  );
};

export default ContactCallAction;
