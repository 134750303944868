import React, { Suspense } from "react";
import { useInView } from "react-intersection-observer";
import LoadingPage from "../Common/LoadingPage";
import ServicesSection from "./ServicesSection";

const ServicesWrapper = () => {
  const [ref, inView] = useInView({ threshold: 0.5, triggerOnce: true });
  return (
    <div
      ref={ref}
      className={`max-w-6xl mx-auto flex flex-col justify-center items-center gap-10 px-4 lg:px-0 ${
        inView ? "slide-in-left" : "opacity-100 md:opacity-0"
      }`}
    >
      <Suspense fallback={<LoadingPage />}>
        <ServicesSection />
      </Suspense>
    </div>
  );
};

export default ServicesWrapper;
