import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import drukResidence from "../assets/works/druk_residence.png";
import bholaSharma from "../assets/works/bhola_sharma.png";
import civilWorkShop from "../assets/gallery/workshop/cover.png";
import techSupervision from "../assets/gallery/supervision/1.png";
import { Card } from "../components/Cards/Card";
import bholaSharmaGallery from "../components/Common/BholaSharma";
import civilWorkshopGallery from "../components/Common/CivilWorkshop";
import techSupervisionGallery from "../components/Common/TechSupervision";
import drukGallery from "../components/Common/Druk";
import Breadcrumb from "../components/Common/BreadcrumbBanner/Breadcrumb";
import LoadingPage from "../components/Common/LoadingPage";

export const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [items, setItems] = useState([]);
  const lightGallery = useRef(null);
  const openLightBox = (index) => {
    if (index === 1) {
      setItems(bholaSharmaGallery);
      if (items.length > 0 && items[0].thumb === bholaSharmaGallery[0].thumb)
        lightGallery.current.openGallery();
    } else if (index === 2) {
      setItems(drukGallery);
      if (items.length > 0 && items[0].thumb === drukGallery[0].thumb)
        lightGallery.current.openGallery();
    } else if (index === 3) {
      setItems(civilWorkshopGallery);
      if (items.length > 0 && items[0].thumb === civilWorkshopGallery[0].thumb)
        lightGallery.current.openGallery();
    } else {
      setItems(techSupervisionGallery);
      if (
        items.length > 0 &&
        items[0].thumb === techSupervisionGallery[0].thumb
      )
        lightGallery.current.openGallery();
    }
  };

  useEffect(() => {
    if (items.length > 0) {
      lightGallery.current.refresh(items);
      lightGallery.current.openGallery();
    }
  }, [items]);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  return (
    <>
      <Breadcrumb title={"Gallery"} mainNav={"Gallery"} />
      <div className="max-w-6xl mx-auto flex flex-col justify-center items-center gap-4 px-4 lg:px-0 my-6 mt-7">
        <Suspense fallback={<LoadingPage />}>
          <LightGallery
            elementClassNames="custom-classname"
            dynamic
            dynamicEl={items}
            download={false}
            getCaptionFromTitleOrAlt={true}
            onInit={onInit}
          ></LightGallery>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-3 fade-in">
            <Card
              src={bholaSharma}
              title={"Bhola Sharma, Siliguri - Interior"}
              openLightBox={openLightBox}
              listIndex={1}
            />
            <Card
              src={drukResidence}
              title={"Druk Residence - Devidanga, Siliguri"}
              openLightBox={openLightBox}
              listIndex={2}
            />
            <Card
              src={civilWorkShop}
              title={"Civil Engineering Workshop"}
              openLightBox={openLightBox}
              listIndex={3}
            />
            <Card
              src={techSupervision}
              title={"Technical Supervision"}
              openLightBox={openLightBox}
              listIndex={4}
            />
          </div>
        </Suspense>
      </div>
    </>
  );
};
