import React from "react";

const Section = (props) => {
  return (
    <section
      className={`flex flex-col px-4 lg:px-0 items-center justify-center ${props.classes}`}
    >
      {props.children}
    </section>
  );
};

export default Section;
